import React from 'react';
import ReduxToastr from 'react-redux-toastr';

import './Toastr.scss';

const Toastr = () => (
  <ReduxToastr />
);

export default Toastr;
