import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { useCookies } from 'react-cookie';

import history from 'core/history';
import store from 'core/store';
import HTTPService from 'core/services/';
import Toastr from 'shared/Toastr';
import Header from 'shared/Header';
import Footer from 'shared/Footer';

import 'App.scss';

const ResumeUpload = lazy(() => import('features/ResumeUpload'));
const ReturnedResume = lazy(() => import('features/ReturnedResume'));

function App() {
  const [cookies] = useCookies();
  const csrfToken = cookies.csrftoken;

  HTTPService.useAxiosInterceptors();
  HTTPService.setCsrfToken(csrfToken);

  return (
    <div className="app">
      <Provider store={store}>
        <Router>
          <Header />
          <div className="app__background">
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                <Route exact path="/" component={ResumeUpload} props={history} />
                <Route path="/analyzed" component={ReturnedResume} />
              </Switch>
            </Suspense>
          </div>
          <Footer />
          <Toastr />
        </Router>
      </Provider>
    </div>
  );
}

export default App;
