import React from 'react';
import PropTypes from 'prop-types';

import './IconButton.scss';

const IconButton = ({
  className, iconSrc, width, height, ...props
}) => (
  <button className={`btn-icon ${className}`} type="button" {...props}>
    <img width={width} height={height} src={iconSrc} alt="Icon" />
  </button>
);

IconButton.propTypes = {
  className: PropTypes.string,
  iconSrc: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

IconButton.defaultProps = {
  className: '',
  width: '',
  height: '',
};

export default IconButton;
