import { combineReducers } from 'redux';

import resumeUpload from 'features/ResumeUpload/reducer';
import toastrReducer from 'shared/Toastr/reducer';

const rootReducer = combineReducers({
  resumeUpload,
  ...toastrReducer,
});

export default rootReducer;
