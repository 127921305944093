import axios from 'axios';
import setRequestHeadersInterceptor from '../interceptors/requestInterceptor';

class HTTPService {
  static useAxiosInterceptors() {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  }

  static getCancelToken(source) {
    return source.token;
  }

  static setCsrfToken(csrfToken) {
    axios.interceptors.request
      .use((prevConfig) => setRequestHeadersInterceptor(prevConfig, csrfToken));
  }

  static cancelRequest(source) {
    const message = 'Cancel request';
    return source.cancel(message);
  }

  static isCancel(error) {
    return axios.isCancel(error);
  }
}

export default HTTPService;
