import React from 'react';
import PropTypes from 'prop-types';

import logo from 'assets/images/logo.svg';

import './Logo.scss';

const Logo = ({ className, ...props }) => (
  <img className={`logo ${className}`} src={logo} alt="Resume parser logo" width="37" height="37" {...props} />
);

Logo.propTypes = {
  className: PropTypes.string,
};

Logo.defaultProps = {
  className: '',
};

export default Logo;
