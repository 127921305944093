import React from 'react';
import { FaTwitter, FaFacebookF, FaLinkedinIn } from 'react-icons/fa';

import SocialLink from 'shared/SocialLink';

import './Footer.scss';

const Footer = () => (
  <div className="footer">
    <nav className="footer__nav">
      <a className="footer__nav-link" href>Terms of Service</a>
      <a className="footer__nav-link" href>Privacy Policy</a>
    </nav>
    <span className="footer__copyright">© 2021 CV Parser</span>
    <div className="footer__social">
      <SocialLink href="" className="footer__social-link footer__social-link-fb">
        <FaFacebookF className="footer__social-icon" />
      </SocialLink>
      <SocialLink href="" className="footer__social-link footer__social-link-tw">
        <FaTwitter className="footer__social-icon" />
      </SocialLink>
      <SocialLink href="" className="footer__social-link footer__social-link-in">
        <FaLinkedinIn className="footer__social-icon" />
      </SocialLink>
    </div>
    <a
      className="footer__email"
      href="mailto:hello@cvparser.com?subject=CV Parser"
    >
      hello@cvparser.com
    </a>
  </div>
);

export default Footer;
