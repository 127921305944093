import React from 'react';
import propTypes from 'prop-types';

const SocialLink = ({ children, href, className }) => (
  <a className={className} href={href}>
    {children}
  </a>
);

SocialLink.propTypes = {
  children: propTypes.element.isRequired,
  href: propTypes.string.isRequired,
  className: propTypes.string.isRequired,
};

export default SocialLink;
