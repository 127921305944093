import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './RouterButton.scss';

const RouterButton = ({
  className, path, children, ...props
}) => (
  <Link className={`btn btn-empty ${className}`} to={path} {...props}>
    {children}
  </Link>
);

RouterButton.propTypes = {
  className: PropTypes.string,
  path: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
  ]),
};

RouterButton.defaultProps = {
  className: '',
  children: '',
};

export default RouterButton;
