import {
  ANALYZED_RESUME_REQUEST,
  ANALYZED_RESUME_FAILURE,
  ANALYZED_RESUME_SUCCESS,
} from './actionsTypes';

const initialState = {
  isLoading: false,
  error: {},
  data: {},
};

const getAnalyzedResumeReducer = (state = initialState, {
  type, data, error,
}) => {
  switch (type) {
    case ANALYZED_RESUME_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ANALYZED_RESUME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data,
        errors: {},
      };
    case ANALYZED_RESUME_FAILURE:
      return {
        ...state,
        isLoading: false,
        error,
      };

    default:
      return state;
  }
};

export default getAnalyzedResumeReducer;
