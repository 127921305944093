import React from 'react';
import { Link } from 'react-router-dom';

import Logo from 'shared/Logo';
import RouterButton from 'shared/RouterButton/RouterButton';
import IconButton from 'shared/IconButton';
import mobMenuIcon from 'assets/images/mob-menu.svg';

import './Header.scss';

const Header = () => (
  <div className="header">
    <Logo className="header__logo" />
    <nav className="header__nav">
      <Link className="header__nav-link" to="/">How it works</Link>
      <Link className="header__nav-link" to="/">Pricing</Link>
      <Link className="header__nav-link" to="/">Blog</Link>
      <Link className="header__nav-link" to="/">Contacts</Link>
    </nav>
    <RouterButton path="/" className="header__btn-upload">Upload cv</RouterButton>
    <IconButton iconSrc={mobMenuIcon} className="header__btn-menu" width="26" height="18" />
  </div>
);

export default Header;
